import { addStmtToSchemaDsl, setSchemaDsl } from '@/state/features/schema/schemaSlice'
import { applicationType } from '@contember-cloud/studio-dsl'
import { Plugin, ProjectSliceState } from '@contember-cloud/studio-server'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { addStmtToStructureDsl, setStructure, setStructureDsl } from '../structure/structureSlice'

const initialState: ProjectSliceState = {
	id: undefined,
	personId: undefined,
	isStreaming: false,
	isSaved: true,
	plugins: [],
}

export const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setProjectId(state, action: PayloadAction<string>) {
			state.id = action.payload
		},
		setProjectIntent(state, action: PayloadAction<string>) {
			state.intent = action.payload
			state.isSaved = false
		},
		setProjectName(state, action: PayloadAction<string>) {
			state.name = action.payload
			state.isSaved = false
		},
		setProjectType(state, action: PayloadAction<string | undefined>) {
			const parsedType = applicationType.safeParse(action.payload)
			state.type = parsedType.success ? parsedType.data : undefined
			state.isSaved = false
		},
		setProjectSpecification(state, action: PayloadAction<string>) {
			state.specification = action.payload
			state.isSaved = false
		},
		resetProject() {
			return initialState
		},
		setProjectIsSaved(state, action: PayloadAction<boolean>) {
			state.isSaved = action.payload
		},
		setProject(state, action: PayloadAction<ProjectSliceState>) {
			return action.payload
		},
		setShowDebugger(state, action: PayloadAction<boolean>) {
			state.showDebugger = action.payload
		},
		setProjectIsStreaming(state, action: PayloadAction<boolean>) {
			state.isStreaming = action.payload
		},
		setProjectOrganizationId(state, action: PayloadAction<string>) {
			state.organizationId = action.payload
		},
		setPlugins(state, action: PayloadAction<Plugin[]>) {
			state.plugins = action.payload
		},
	},
	extraReducers: builder => {
		builder
			.addCase(setStructureDsl.fulfilled, (state, action) => {
				if (!state.isStreaming) {
					state.isDownloadable = true
				}
			})
			.addCase(addStmtToSchemaDsl, (state, action) => {
				state.isSaved = false
			})
			.addCase(setSchemaDsl, (state, action) => {
				state.isSaved = false
			})
			.addCase(setStructure, (state, action) => {
				state.isSaved = false
			})
			.addCase(addStmtToStructureDsl, (state, action) => {
				state.isSaved = false
			})
	},
})

export const {
	setProjectId,
	setProjectName,
	setProjectType,
	setProjectIsSaved,
	resetProject,
	setProject,
	setShowDebugger,
	setProjectIsStreaming,
	setProjectOrganizationId,
	setProjectIntent,
	setProjectSpecification,
} = projectSlice.actions

export const selectProjectState = (state: RootState) => state.project

export default projectSlice.reducer
